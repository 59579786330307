<template>
  <div class="body container">
    <Breadcrumb />
    <div>
      <h1>
        {{ thePageTitle }}
        <router-link :to="{ name: 'addShortUrl' }">
          <i class="fas fa-plus" />
        </router-link>
      </h1>
      <hr class="separate-line" />
      <el-table :data="shortUrls">
        <el-table-column prop="default_short_url" label="短網址">
          <template slot-scope="scope">
            <a target="_blank" :href="scope.row.default_short_url">
              {{ scope.row.default_short_url }}
            </a>
          </template>
        </el-table-column>
        <el-table-column prop="full_url" label="完整網址">
          <template slot-scope="scope">
            <a target="_blank" :href="scope.row.full_url">
              {{ scope.row.full_url }}
            </a>
          </template>
        </el-table-column>
        <el-table-column width="120px" prop="visit_count" label="點擊次數" />
        <el-table-column width="100px" label="Action">
          <template slot-scope="scope">
            <DeleteIconButton :onDelete="deleteShortUrl(scope.row.id)" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import DeleteIconButton from "@/components/button/DeleteIconButton";

export default {
  metaInfo() {
    return {
      title: `${this.thePageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    DeleteIconButton
  },
  async created() {
    this.$store.dispatch("shortUrls/getShortUrls");
  },
  computed: {
    thePageTitle() {
      return this.$t("pageTitle.shortUrls");
    },
    shortUrls() {
      return this.$store.getters["shortUrls/shortUrls"];
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteShortUrl(shortUrlId) {
      return async () => {
        await this.$store.dispatch("shortUrls/deleteShortUrls", shortUrlId);
        this.$store.dispatch("shortUrls/getShortUrls");
      };
    }
  }
};
</script>
